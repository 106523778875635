<template>
    <div>
        <div v-if="parameters.p_144" class="py-2">
            <strong>R1</strong>
            <b-btn v-b-toggle="'r1Pump'" variant="primary" class="collapse-btn-style" @pointerdown="r1CollapseOpen = !r1CollapseOpen">{{
                r1CollapseIcon }}
            </b-btn>
            <b-collapse id="r1Pump" class="mt-2">
                <b-card style="border: none;">
                    <br>

                    <Parameter key="p_144" param-key="p_144" :param-value="parameters['p_144']"
                               :free-analogue-controller-exists="freeAnalogueControllerExists('r1')"/>

                    <Parameter key="p_153" param-key="p_153" :param-value="parameters['p_153']"/>

                    <div v-if="isNotPumpStandard('p_144')">
                        <Parameter v-if="categoriesLookup['Pumps'].indexOf(key) > -1 && shouldRenderParameter(key)"
                                   v-for="(value, key) in parameters" :key="key" :param-key="key" :param-value="value"/>
                    </div>

                    <Parameter key="p_49" param-key="p_49" :param-value="parameters['p_49']"
                               :free-analogue-controller-exists="freeAnalogueControllerExists('r1')"/>

                    <div class="py-2">
                        <strong>PIPE</strong>
                        <hr>
                    </div>

                    <Parameter key="p_168" param-key="p_168" :param-value="parameters['p_168']"/>
                    <div v-if="parameters['p_168'].value == 1">
                        <Parameter key="p_169" param-key="p_169" :param-value="parameters['p_169']"/>
                        <Parameter key="p_170" param-key="p_170" :param-value="parameters['p_170']"/>
                        <Parameter key="p_171" param-key="p_171" :param-value="parameters['p_171']"/>
                        <Parameter key="p_172" param-key="p_172" :param-value="parameters['p_172']"/>
                    </div>
                </b-card>
            </b-collapse>
        </div>
        <div v-else-if="parameters.p_144" class="py-2">
            <strong>R1 - no controller available</strong>
        </div>

        <div v-if="parameters.p_145" class="py-2">
            <strong>R2</strong>
            <b-btn v-b-toggle="'r2Pump'" variant="primary" class="collapse-btn-style" @pointerdown="r2CollapseOpen = !r2CollapseOpen">{{
                r2CollapseIcon }}
            </b-btn>
            <b-collapse id="r2Pump" class="mt-2">
                <b-card style="border: none;">
                    <br>

                    <Parameter key="p_145" param-key="p_145" :param-value="parameters['p_145']"
                               :free-analogue-controller-exists="freeAnalogueControllerExists('r2')"></Parameter>

                    <Parameter key="p_154" param-key="p_154" :param-value="parameters['p_154']"/>

                    <div v-if="isNotPumpStandard('p_145')">
                        <Parameter v-if="categoriesLookup['Pumps'].indexOf(key) > -1 && shouldRenderParameter(key)" v-for="(value, key) in parameters"
                                   :key="key" :param-key="key" :param-value="value"></Parameter>
                    </div>

                    <Parameter key="p_50" param-key="p_50" :param-value="parameters['p_50']"
                               :free-analogue-controller-exists="freeAnalogueControllerExists('r2')"></Parameter>
                </b-card>
            </b-collapse>
        </div>
        <div v-else-if="parameters.p_145" class="py-2">
            <strong>R2 - no controller available</strong>
        </div>

        <div v-if="parameters.p_146 && !((parameters.p_144 && parameters.p_144.value == 1 ) || (parameters.p_145 && parameters.p_145.value == 1))"
             class="py-2">
            <strong>R3</strong>
            <b-btn v-b-toggle="'r3Pump'" variant="primary" class="collapse-btn-style" @pointerdown="r3CollapseOpen = !r3CollapseOpen">{{
                r3CollapseIcon }}
            </b-btn>
            <b-collapse id="r3Pump" class="mt-2">
                <b-card style="border: none;">
                    <br>

                    <Parameter key="p_146" param-key="p_146" :param-value="parameters['p_146']"
                               :free-analogue-controller-exists="freeAnalogueControllerExists('r3')"></Parameter>

                    <Parameter key="p_155" param-key="p_155" :param-value="parameters['p_155']"/>

                    <div v-if="isNotPumpStandard('p_146')">
                        <Parameter v-if="categoriesLookup['Pumps'].indexOf(key) > -1 && shouldRenderParameter(key)"
                                   v-for="(value, key) in parameters" :key="key" :param-key="key" :param-value="value"></Parameter>
                    </div>

                    <Parameter key="p_51" param-key="p_51" :param-value="parameters['p_51']"
                               :free-analogue-controller-exists="freeAnalogueControllerExists('r3')"></Parameter>
                </b-card>
            </b-collapse>
        </div>
        <div v-else-if="parameters.p_146" class="py-2">
            <strong>R3 - no controller available</strong>
        </div>
    </div>
</template>

<script>
    import Parameter from "@/components/virtual-device/edit/parameter/v3/Parameter";
    import DeviceTemplates from "@/components/virtual-device/edit/device-templates/DeviceTemplates";

    export default {
        name: "Pumps",

        components: {
            DeviceTemplates,
            Parameter
        },

        props: [
            'parameters',
            'categoriesLookup',
            'shouldShow',
            'shouldRenderMobileSchedule'
        ],

        data() {
            return {
                r1CollapseOpen: false,
                r2CollapseOpen: false,
                r3CollapseOpen: false
            }
        },

        computed: {
            r1CollapseIcon: function () {
                return this.r1CollapseOpen === false ? '+' : '-';
            },

            r2CollapseIcon: function () {
                return this.r2CollapseOpen === false ? '+' : '-';
            },

            r3CollapseIcon: function () {
                return this.r3CollapseOpen === false ? '+' : '-';
            }
        },

        mounted() {
            this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
                if (!isJustShown) {
                    if (collapseId === 'r1Pump') {
                        this.r1CollapseOpen = false;
                    } else if (collapseId === 'r2Pump') {
                        this.r2CollapseOpen = false;
                    } else if (collapseId === 'r3Pump') {
                        this.r3CollapseOpen = false;
                    }
                }
            })
        },

        methods: {
            freeAnalogueControllerExists(pump) {
                if (pump === "r1") {
                    return !((this.parameters.p_145 && this.parameters.p_145.value == 1) || (this.parameters.p_146 && this.parameters.p_146.value == 1));
                } else if (pump === "r2") {
                    return !((this.parameters.p_144 && this.parameters.p_144.value == 1) || (this.parameters.p_146 && this.parameters.p_146.value == 1));
                } else if (pump === "r3") {
                    return !((this.parameters.p_144 && this.parameters.p_144.value == 1) || (this.parameters.p_145 && this.parameters.p_145.value == 1));
                } else return false;
            },

            isNotPumpStandard(key) {
                return this.parameters[key].value != 0;
            },

            shouldRenderParameter(key) {
                if (key === 'p_138' || key === 'p_139' || key === 'p_140') return this.parameters.p_134.value != 0;
                else if (key === 'p_135' || key === 'p_136' || key === 'p_137') return this.parameters.p_134.value != 1;
                else if (key === 'p_134' || key === 'p_141' || key === 'p_142' || key === 'p_143') return true;
                else return false;
            }
        }
    }
</script>

<style scoped>
    .collapse-btn-style {
        padding: 0rem 0.4rem;
        margin: 0px 10px 0px 10px;
        min-width: 1.5rem; /*Needed because button gets narrower when icon is changed from '+' to '-'*/
    }

    .card-body {
        padding: 0;
    }

</style>