<template>
    <div v-if="getAvailableSensors[0] === 1 || getAvailableSensors[1] === 1 || getAvailableSensors[2] === 1 || getAvailableSensors[3] === 1"
         class="py-2">

        <strong>Monitor</strong>
        <b-btn v-b-toggle="'moduleMonitor'" variant="primary" class="collapse-btn-style" @pointerdown="collapseOpen = !collapseOpen"
               style="margin: 0px 10px 0px 20px;">{{ collapseIcon }}
        </b-btn>
        <b-collapse id="moduleMonitor" class="mt-2">
            <b-card style="border: none;">
                <hr style="margin-top: 0.4rem;">

                <parameter param-key="p_181" :param-value="parameters['p_181']"/>
                <div v-if="parameters['p_181'].value != 0">
                    <parameter v-if="getAvailableSensors[0] == 1" param-key="p_182" :param-value="parameters['p_182']"/>
                    <parameter v-if="getAvailableSensors[1] == 1" param-key="p_183" :param-value="parameters['p_183']"/>
                    <parameter v-if="getAvailableSensors[2] == 1" param-key="p_184" :param-value="parameters['p_184']"/>
                    <parameter v-if="getAvailableSensors[3] == 1" param-key="p_185" :param-value="parameters['p_185']"/>
                </div>

            </b-card>
        </b-collapse>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import Parameter from "@/components/virtual-device/edit/parameter/v3/Parameter";

    export default {
        name: "ModuleMonitor",

        components: {
            Parameter
        },

        data() {
            return {
                collapseOpen: false
            }
        },

        computed: {
            ...mapGetters({
                getParameters: 'virtualDevice/parameters',
                getDeviceVariant: 'virtualDevice/deviceVariant'
            }),

            parameters: function () {
                return this.getParameters(this.$route.params.id);
            },

            collapseIcon: function () {
                return this.collapseOpen === false ? '+' : '-';
            },

            getAvailableSensors: function () {
                let deviceVariant = this.getParameters(this.$route.params.id).p_1.value;

                if (deviceVariant === 1) return this.applyUnavailableSensors([1, 1, 1, 1]);
                else if (deviceVariant === 2) return this.applyUnavailableSensors([0, 0, 1, 1]);
                else if (deviceVariant === 3) return this.applyUnavailableSensors([0, 0, 0, 1]);
                else if (deviceVariant === 4) return this.applyUnavailableSensors([0, 0, 0, 1]);
                else if (deviceVariant === 5) return this.applyUnavailableSensors([0, 0, 0, 1]);
                else if (deviceVariant === 6) return this.applyUnavailableSensors([0, 0, 1, 1]);
                else if (deviceVariant === 7) return this.applyUnavailableSensors([0, 0, 0, 1]);
                else if (deviceVariant === 8) return this.applyUnavailableSensors([0, 0, 0, 1]);
                else if (deviceVariant === 9) return this.applyUnavailableSensors([0, 0, 0, 0]);
                else if (deviceVariant === 10) return this.applyUnavailableSensors([0, 0, 0, 1]);
                else if (deviceVariant === 11) return this.applyUnavailableSensors([1, 1, 1, 1]);
                else if (deviceVariant === 12) return this.applyUnavailableSensors([0, 0, 1, 1]);
                else return [0, 0, 0, 0];
            }
        },

        watch: {
            getAvailableSensors: function (value) {
                if (value[0] == 0 && value[1] == 0 && value[2] == 0 && value[3] == 0) {
                    this.collapseOpen = false; //in case collapse is open when v-if is true when it shows again it is buggy
                }
            }
        },

        methods: {
            applyUnavailableSensors(variantSensors) {
                let availableSensors = variantSensors;
                let parameters = this.getParameters(this.$route.params.id);

                if (parameters.p_78) {
                    if (parameters.p_78.value == 1) {
                        availableSensors[parameters.p_80.value] = 0;
                    } else if (parameters.p_78.value == 2) {
                        availableSensors[parameters.p_80.value] = 0;
                        availableSensors[parameters.p_177.value] = 0;
                    } else if (parameters.p_78.value == 4) {
                        availableSensors[parameters.p_150.value] = 0;
                        availableSensors[parameters.p_247.value] = 0;
                    } else if (parameters.p_78.value == 5) {
                        availableSensors[parameters.p_188.value] = 0;
                        availableSensors[parameters.p_189.value] = 0;
                    } else if (parameters.p_78.value == 6) {
                        availableSensors[parameters.p_206.value] = 0;
                        availableSensors[parameters.p_207.value] = 0;
                    } else if (parameters.p_78.value == 8) {
                        availableSensors[parameters.p_173.value] = 0;
                    }
                }

                if (parameters.p_81) {
                    if (parameters.p_81.value == 1) {
                        availableSensors[parameters.p_83.value] = 0;
                    } else if (parameters.p_81.value == 2) {
                        availableSensors[parameters.p_83.value] = 0;
                        availableSensors[parameters.p_178.value] = 0;
                    } else if (parameters.p_81.value == 4) {
                        availableSensors[parameters.p_151.value] = 0;
                        availableSensors[parameters.p_249.value] = 0;
                    } else if (parameters.p_81.value == 5) {
                        availableSensors[parameters.p_194.value] = 0;
                        availableSensors[parameters.p_195.value] = 0;
                    } else if (parameters.p_81.value == 6) {
                        availableSensors[parameters.p_212.value] = 0;
                        availableSensors[parameters.p_213.value] = 0;
                    } else if (parameters.p_81.value == 8) {
                        availableSensors[parameters.p_174.value] = 0;
                    }
                }

                if (parameters.p_84) {
                    if (parameters.p_84.value == 1) {
                        availableSensors[parameters.p_86.value] = 0;
                    } else if (parameters.p_84.value == 2) {
                        availableSensors[parameters.p_86.value] = 0;
                        availableSensors[parameters.p_179.value] = 0;
                    } else if (parameters.p_84.value == 4) {
                        availableSensors[parameters.p_152.value] = 0;
                        availableSensors[parameters.p_252.value] = 0;
                    } else if (parameters.p_84.value == 5) {
                        availableSensors[parameters.p_200.value] = 0;
                        availableSensors[parameters.p_201.value] = 0;
                    } else if (parameters.p_84.value == 6) {
                        availableSensors[parameters.p_218.value] = 0;
                        availableSensors[parameters.p_219.value] = 0;
                    } else if (parameters.p_84.value == 8) {
                        availableSensors[parameters.p_175.value] = 0;
                    }
                }

                return availableSensors;
            }
        }
    }
</script>

<style scoped>
    .collapse-btn-style {
        padding: 0rem 0.4rem;
        margin: 0px 10px 0px 10px;
        min-width: 1.5rem; /*Needed because button gets narrower when icon is changed from '+' to '-'*/
    }

    .card-body {
        padding: 0;
    }
</style>