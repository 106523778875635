<template>
    <b-tabs no-key-nav pills card id="device-settings" content-class="edit-section-tab">
        <b-tab key="1" title="General" title-link-class="bTabLinkClass">
            <template slot="title"><i class="icon-settings tab-icon px-2" style=";"></i></template>

            <GeneralSettings></GeneralSettings>
        </b-tab>

        <b-tab key="2" lazy v-if="shouldShow('Settings')" title-link-class="bTabLinkClass">
            <template slot="title"><i class="icon-wrench tab-icon px-2"></i></template>
            <h4 style="padding-bottom: 1.2em;"><i><em>{{ $t('deviceEdit.solarSettings') }}</em></i></h4>

            <settings :parameters="parameters" :categories-lookup="categoriesLookup" :shouldRenderMobileSchedule="shouldRenderMobileSchedule"
                      :shouldShow="shouldShow"/>

            <div v-if="parameters.p_144 || parameters.p_145 || parameters.p_146 && !((parameters.p_144 && parameters.p_144.value == 1 )
                    || (parameters.p_145 && parameters.p_145.value == 1))">
                <strong>{{ $t('deviceEdit.pumps') }}</strong>
                <hr>
                <pumps :parameters="parameters" :categories-lookup="categoriesLookup"></pumps>
            </div>
        </b-tab>

        <b-tab key="3" lazy v-if="shouldShow('Modules')" title-link-class="bTabLinkClass">
            <template slot="title"><i class="icon-layers tab-icon px-2"></i></template>
            <h4 style="padding-bottom: 1.2em;"><i><em>{{ $t('deviceEdit.modules') }}</em></i></h4>

            <Module :module-number="1" :module-name="'Module 1'" :modules-lookup="this.categoriesLookup['Modules']"></Module>

            <Module :module-number="2" :module-name="'Module 2'" :modules-lookup="this.categoriesLookup['Modules']"></Module>

            <Module :module-number="3" :module-name="'Module 3'" :modules-lookup="this.categoriesLookup['Modules']"></Module>

            <ModuleMonitor></ModuleMonitor>
        </b-tab>

        <b-tab key="4" lazy v-if="shouldShow('Protections')" title-link-class="bTabLinkClass">
            <template slot="title"><i class="icon-shield tab-icon px-2"></i></template>
            <h4 style="padding-bottom: 1.2em;"><i><em>{{ $t('deviceEdit.protections') }}</em></i></h4>

            <protections :parameters="parameters" :categories-lookup="categoriesLookup" :should-show="shouldShow"/>
        </b-tab>

        <b-tab key="5" lazy v-if="shouldShow('Manual Operations')" title-link-class="bTabLinkClass">
            <template slot="title"><i class="icon-game-controller tab-icon px-2"></i></template>
            <h4 style="padding-bottom: 1.2em;"><i><em>{{ $t('deviceEdit.manualOperations') }}</em></i></h4>

            <manual-operations :parameters="parameters" :categories-lookup="categoriesLookup"></manual-operations>
        </b-tab>

        <b-tab key="6" lazy v-if="shouldShow('Root Setup')" title-link-class="bTabLinkClass">
            <template slot="title"><i class="icon-magic-wand tab-icon px-2"></i></template>
            <h4 style="padding-bottom: 1.2em;"><i><em>{{ $t('deviceEdit.setup') }}</em></i></h4>

            <setup :parameters="parameters" :categories-lookup="categoriesLookup" :should-show="shouldShow"></setup>
        </b-tab>
    </b-tabs>
</template>

<script>
    import {mapGetters} from 'vuex';
    import Header from "@/components/layout/Header";
    import GeneralSettings from "@/components/virtual-device/edit/general-settings/GeneralSettings";
    import Settings from "@/components/virtual-device/edit/shared/Settings";
    import Pumps from "@/components/virtual-device/edit/pleion/v1/Pumps";
    import Protections from "@/components/virtual-device/edit/shared/Protections";
    import ManualOperations from "@/components/virtual-device/edit/shared/ManualOperations";
    import Setup from "@/components/virtual-device/edit/shared/Setup/Setup";
    import Module from "@/components/virtual-device/edit/modules/ModuleV3";
    import ModuleMonitor from "@/components/virtual-device/edit/pleion/v1/ModuleMonitorPleionV1";
    import Parameter from "@/components/virtual-device/edit/parameter/v3/Parameter";

    export default {
        name: "PleionV1",

        components: {
            Header,
            GeneralSettings,
            Settings,
            Pumps,
            Protections,
            ManualOperations,
            Setup,
            Module,
            ModuleMonitor,
            Parameter
        },

        data() {
            return {
                categoriesLookup: {
                    "Settings": {
                        "Root Settings": ["p_6", "p_7", "p_8", "p_9", "p_27", "p_28", "p_29", "p_30", "p_31", "p_48", "p_243", "p_244", "p_245",
                            "p_246", "p_256", "p_257", "p_258", "p_259", "p_260"],
                        "Heat Transfer": ["p_64", "p_65", "p_66", "p_67"],
                        "Heat Pump": ["p_41", "p_42", "p_43", "p_44", "p_45", "p_46", "p_47", "p_68", "p_69"]
                    },
                    "Protections": {
                        "System": ["p_10", "p_11", "p_12"],
                        "Collector": ["p_13", "p_14", "p_15", "p_16"],
                        "Frost": ["p_19", "p_20", "p_21"],
                        "Seizing": ["p_22", "p_23", "p_24"]
                    },
                    "Setup": {
                        "Root Setup": ["p_1"],
                        "Datetime": ["p_70", "p_71"],
                        "Sensors Config": ["p_155", "p_156", "p_157", "p_158", "p_159", "p_160", "p_161", "p_162", "p_163"],
                        "Holiday": ["p_235", "p_236", "p_237", "p_238", "p_239", "p_240", "p_241", "p_242"]
                    },
                    "Manual Operations": ["p_72", "p_73", "p_74", "p_75"],
                    "Modules": {
                        //Each module contains parameters for each type i.e. thermostat, on/off etc.
                        "Module 1": ["p_78", "p_35", "p_36", "p_37", "p_38", "p_39", "p_40", "p_53", "p_56", "p_57", "p_58", "p_63", "p_79", "p_80", "p_101",
                            "p_102", "p_103", "p_104", "p_150", "p_165", "p_173", "p_177", "p_187", "p_188", "p_189", "p_190", "p_191", "p_192",
                            "p_205", "p_206", "p_207", "p_208", "p_209", "p_210", "p_223", "p_224", "p_225", "p_226" ,"p_247", "p_248", "p_253"],
                        "Module 2": ["p_81", "p_54", "p_59", "p_60", "p_61", "p_82", "p_83", "p_87", "p_88", "p_89", "p_90", "p_91", "p_92", "p_93", "p_105",
                            "p_106", "p_107", "p_108", "p_151", "p_166", "p_174", "p_178", "p_193", "p_194", "p_195", "p_196", "p_197", "p_198",
                            "p_211", "p_212", "p_213", "p_214", "p_215", "p_216", "p_227", "p_228", "p_229", "p_230", "p_249", "p_250", "p_254"],
                        "Module 3": ["p_55", "p_84", "p_85", "p_86", "p_94", "p_95", "p_96", "p_97", "p_98", "p_99", "p_100", "p_109", "p_110", "p_111", "p_112",
                            "p_147", "p_148", "p_149", "p_152", "p_167", "p_175", "p_177", "p_179", "p_199", "p_200", "p_201", "p_202", "p_203", "p_204",
                            "p_217", "p_218", "p_219", "p_220", "p_221", "p_222", "p_230", "p_231", "p_232", "p_233", "p_251", "p_252", "p_255"]
                    },
                    "Pumps": ["p_134", "p_135", "p_136", "p_137", "p_138", "p_139", "p_140", "p_141", "p_142", "p_143", "p_153", "p_154", "p_164",
                        "p_168", "p_169", "p_170", "p_171", "p_172"]
                },
                windowWidth: 0
            }
        },

        watch: {
            windowWidth: function (newWidth, oldWidth) {
                if (oldWidth <= 976 && newWidth > 976) { //execute only when passing the 976 breakpoint for the first time
                    this.$nextTick(() => {
                        this.$root.$emit("mount-timeline", 0);
                    })
                }
            }
        },

        computed: {
            ...mapGetters({
                getParameters: 'virtualDevice/parameters'
            }),

            parameters: function () {
                return this.getParameters(this.$route.params.id);
            },

            shouldRenderMobileSchedule: function () {

                return this.windowWidth <= 976;
            }
        },

        created() {
            window.addEventListener('resize', this.setWindowWidth);

            this.windowWidth = window.innerWidth;
        },

        destroyed() {
            window.removeEventListener('resize', this.setWindowWidth);
        },

        methods: {
            setWindowWidth() {
                this.windowWidth = window.innerWidth;
            },

            /**
             * Checks if a category should render or not
             * @param categoryName
             * @returns {boolean}
             */
            shouldShow(categoryName) {
                let parameters = Object.keys(this.parameters);

                if (categoryName === "Root Settings" && this.categoriesLookup['Settings']['Root Settings'] !== undefined) {
                    return parameters.some(r => this.categoriesLookup['Settings']['Root Settings'].includes(r));
                } else if (categoryName === "Heat " && this.categoriesLookup['Settings']['Thermostat'] !== undefined) {
                    return parameters.some(r => this.categoriesLookup['Settings']['Thermostat'].includes(r));
                } else if (categoryName === "Recycle" && this.categoriesLookup['Settings']['Recycle'] !== undefined) {
                    return parameters.some(r => this.categoriesLookup['Settings']['Recycle'].includes(r));
                } else if (categoryName === "Heat Transfer" && this.categoriesLookup['Settings']['Heat Transfer'] !== undefined) {
                    return parameters.some(r => this.categoriesLookup['Settings']['Heat Transfer'].includes(r));
                } else if (categoryName === "Heat Pump" && this.categoriesLookup['Settings']['Heat Pump'] !== undefined) {
                    return parameters.some(r => this.categoriesLookup['Settings']['Heat Pump'].includes(r));
                } else if (categoryName === "System" && this.categoriesLookup['Protections']['System'] !== undefined) {
                    return parameters.some(r => this.categoriesLookup['Protections']['System'].includes(r));
                } else if (categoryName === "Collector" && this.categoriesLookup['Protections']['Collector'] !== undefined) {
                    return parameters.some(r => this.categoriesLookup['Protections']['Collector'].includes(r));
                } else if (categoryName === "Frost" && this.categoriesLookup['Protections']['Frost'] !== undefined) {
                    return parameters.some(r => this.categoriesLookup['Protections']['Frost'].includes(r));
                } else if (categoryName === "Seizing" && this.categoriesLookup['Protections']['Seizing'] !== undefined) {
                    return parameters.some(r => this.categoriesLookup['Protections']['Seizing'].includes(r));
                } else if (categoryName === "Datetime" && this.categoriesLookup['Setup']['Datetime'] !== undefined) {
                    return parameters.some(r => this.categoriesLookup['Setup']['Datetime'].includes(r));
                } else if (categoryName === "Holiday" && this.categoriesLookup['Setup']['Holiday'] !== undefined) {
                    return parameters.some(r => this.categoriesLookup['Setup']['Holiday'].includes(r));
                } else if (categoryName === "Sensors Config" && this.categoriesLookup['Setup']['Sensors Config'] !== undefined) {
                    return parameters.some(r => this.categoriesLookup['Setup']['Sensors Config'].includes(r));
                } else if (categoryName === "Settings" && this.categoriesLookup['Settings'] !== undefined) {
                    return parameters.some(r => this.categoriesLookup['Settings']['Root Settings'].includes(r))
                        || parameters.some(r => this.categoriesLookup['Settings']['Heat Pump'].includes(r))
                        || parameters.some(r => this.categoriesLookup['Settings']['Heat Transfer'].includes(r));
                } else if (categoryName === "Modules" && this.categoriesLookup['Modules'] !== undefined) {
                    return parameters.some(r => this.categoriesLookup['Modules']['Module 1'].includes(r))
                        || parameters.some(r => this.categoriesLookup['Modules']['Module 2'].includes(r))
                        || parameters.some(r => this.categoriesLookup['Modules']['Module 3'].includes(r));
                } else if (categoryName === "Protections" && this.categoriesLookup['Protections'] !== undefined) {
                    return parameters.some(r => this.categoriesLookup['Protections']['System'].includes(r));
                } else if (categoryName === "Manual Operations" && this.categoriesLookup['Manual Operations'] !== undefined) {
                    return parameters.some(r => this.categoriesLookup['Manual Operations'].includes(r));
                } else if (categoryName === "Root Setup" && this.categoriesLookup['Setup']['Root Setup'] !== undefined) {
                    return parameters.some(r => this.categoriesLookup['Setup']['Root Setup'].includes(r));
                } else return false;
            }
        }
    }
</script>

<style scoped>
</style>
